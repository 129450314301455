<template>
    <auth-layout
        :loading="loading"
        :loading-title="$t('global.loadingMessage.loading')"
    >
        <page-header
            v-show="resetDuringSecureFeature"
            :show-close="resetDuringSecureFeature"
            @onClose="$router.go(-1)"
            data-testid="set-password-header"
        />
        <error-view
            v-if="blockingError !== null"
            image-path-in-assets="error-icon.svg"
            :title="blockingError.title"
            :description="blockingError.description"
            :cta="blockingError.cta"
            @click="blockingErrorButtonClicked"
        />
        <div
            v-else
            class="container"
            data-testid="set-password-screen"
        >
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3">
                    <div>
                        <img
                            v-show="!resetDuringSecureFeature"
                            src="../assets/images/global/aven.svg"
                            width="71"
                            class="mb-5"
                            alt=""
                        >
                        <div v-show="showPersonalInfoInput">
                            <h5
                                class="mb-4"
                                v-html="confirmIdentityTitle"
                            />
                            <form-container
                                ref="personalInfoForm"
                                @onSubmit="personInfoInputComplete"
                            >
                                <div
                                    class="col"
                                    data-testid="ssn-entry-group"
                                >
                                    <form-field
                                        name="ssnNumber"
                                        v-model="ssnNumber"
                                        input-type="tel"
                                        inputmode="decimal"
                                        :max-length="4"
                                        :placeholder="$t('pages.SSNVerification.Last4ofSSN')"
                                        validation="required|min:4|max:4"
                                        :autofocus="true"
                                        class="mb-3"
                                        data-testid="personal-info-form-last4Ssn-input"
                                    />
                                </div>
                                <div
                                    class="col"
                                    data-testid="dob-entry-group"
                                >
                                    <form-field-date
                                        ref="dateOfBirthFormField"
                                        name="dateOfBirth"
                                        v-model="dateOfBirth"
                                        :placeholder="$t('pages.SSNVerification.DateOfBirth')"
                                        validation-rules="required"
                                        :focus-placeholder="$t('pages.SSNVerification.dob')"
                                        class="mb-3"
                                        data-testid="personal-info-form-dob-input"
                                    />
                                </div>

                                <base-button
                                    type="submit"
                                    :submitting="submitting"
                                    data-testid="personal-info-form-submit-button"
                                >
                                    {{ $t('pages.SSNVerification.cta') }}
                                </base-button>
                            </form-container>
                        </div>
                        <div v-show="!showPersonalInfoInput">
                            <h5 class="mb-4">
                                {{ $t('pages.setPassword.createPassword') }}
                            </h5>
                            <form-container
                                ref="setPasswordForm"
                                @onSubmit="submitPassword"
                            >
                                <div
                                    class="col"
                                    data-testid="set-password-form-first-password-entry-group"
                                >
                                    <form-field
                                        ref="firstEntry"
                                        v-model="passwordFirstEntry"
                                        name="firstEntry"
                                        input-type="password"
                                        class="mb-2"
                                        validation="prohibited_characters_in_password|required|min:6"
                                        validation-mode="lazy"
                                        :placeholder="$t('pages.setPassword.firstEntryPlaceholder')"
                                        data-testid="set-password-form-password-input"
                                    />
                                </div>
                                <div
                                    class="col"
                                    data-testid="set-password-form-confirm-password-entry-group"
                                >
                                    <form-field
                                        ref="secondEntry"
                                        v-model="passwordConfirmationEntry"
                                        name="secondEntry"
                                        input-type="password"
                                        class="mb-2"
                                        validation="required|confirmPassword:@firstEntry"
                                        validation-mode="aggressive"
                                        :placeholder="$t('pages.setPassword.secondEntryPlaceholder')"
                                        data-testid="set-password-form-confirm-input"
                                    />
                                </div>

                                <base-button
                                    type="submit"
                                    :submitting="submitting"
                                    data-testid="set-password-form-submit-button"
                                >
                                    {{ $t('pages.SSNVerification.cta') }}
                                </base-button>
                            </form-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </auth-layout>
</template>

<script lang="js">
    import AuthLayout from "@/layouts/Auth"
    import BaseButton from "@/components/base/BaseButton";
    import FormContainer from "@/components/base/FormContainer";
    import FormField from "@/components/base/FormField";
    import FormFieldDate from "@/components/base/FormFieldDate";
    import ErrorView from "@/components/ErrorView";
    import {requestSetPasswordToken, setPassword} from "@/services/avenAppApi";
    import {logger} from "@/utils/logger";
    import {i18n} from "@/utils/i18n";
    import {appSessionStorage, localStorageKey} from "@/utils/storage";
    import inspect from "object-inspect";
    import {mapGetters} from "vuex";
    import { postLoginNavigation } from "@/utils/sharedLogic";
    import {ResetError} from "@/data/constants";
    import biometricsPassCodeMixin from '@/utils/biometricsPassCodeMixin'
    import PageHeader from "@/components/PageHeader";

    export default {
        components: {
            PageHeader,
            AuthLayout,
            FormContainer,
            FormField,
            FormFieldDate,
            BaseButton,
            ErrorView,
        },
        watch: {
            ssnNumber(val) {
                if (val.length === 4 && this.$refs.dateOfBirthFormField) {
                    this.$refs.dateOfBirthFormField.setFocus();
                }
            },
            passwordFirstEntry(val) {
                if (val.length > 0) {
                    this.clearCreatePasswordError()
                }
            },
            passwordConfirmationEntry(val) {
                if (val.length > 0) {
                    this.clearCreatePasswordError()
                }
            }
        },
        computed: {
            ...mapGetters(['cardRequiresActivation', 'isPasscodeSet']),
            confirmIdentityTitle() {
                if (this.isPasscodeSet) {
                    return i18n.t('pages.SSNVerification.resetPassword')
                } else {
                    return i18n.t('pages.SSNVerification.setupPassword')
                }
            }
        },
        mixins: [biometricsPassCodeMixin],
        data() {
            return {
                loading: false,
                submitting: false,
                showPersonalInfoInput: true,
                ssnNumber: "",
                dateOfBirth:"",
                passwordFirstEntry: "",
                passwordConfirmationEntry: "",
                blockingError: null,
                resetPasswordToken: '',
                // this flag guards prevents storage clearing actions and login flow navigation action.
                // if the reset was attempted as part of a secure feature(i.e. Balance Transfer or Cash Out)
                //     clearing entire storage would affect user continuing to use other features in the app
                //     especially on mobile when session information is injected from native side into
                //     the webview.
                //
                //     only navigation is allowed is going back 1; back to the secure feature that started password reset.
                resetDuringSecureFeature: false,
            };
        },
        mounted() {
            logger.info(`Got to identity verification`)
            this.resetDuringSecureFeature = this.$route.params.resetDuringSecureFeature || false
        },
        methods: {
            requestResetPasswordToken: async function() {
                try {
                    logger.info(`request new token`)
                    const response = await requestSetPasswordToken()
                    this.resetPasswordToken = response.data.payload.token
                } catch (error) {
                    this.blockingError = this.getGenericBlockError()
                } finally {
                    this.loading = false
                }
            },
            personInfoInputComplete: async function() {
                const isValid = await this.$refs.personalInfoForm.$refs.observer.validate()

                if (!isValid) {
                    return
                }
                this.showPersonalInfoInput = false
            },
            submitPassword: async function() {
                if (this.submitting) {
                    return
                }

                const isValid = await this.$refs.setPasswordForm.$refs.observer.validate()

                if (!isValid) {
                    return
                }

                this.submitting = true
                let isSetPasswordSuccessful = false
                try {
                    await this.requestResetPasswordToken()
                    if (this.blockingError) {
                        this.submitting = false
                        return
                    }
                    const response = await setPassword(
                        this.resetPasswordToken,
                        this.passwordConfirmationEntry,
                        this.ssnNumber,
                        this.dateOfBirth
                    )
                    logger.info(`set password success`)
                    this.resetPasswordToken = ''
                    appSessionStorage.setItem(localStorageKey.jwtToken, response.data.payload.jwt.accessJWT);
                    const shouldInjectSessionToNative = this.isSingleWebView && this.isWebView
                    logger.info(`singleWebView: ${this.isSingleWebView}, isWebView: ${this.isWebView}`)
                    if (shouldInjectSessionToNative) {
                        logger.info(`password has been reset, injecting updated auth data to native`)
                        this.injectAuthInfoToNative(this.passwordConfirmationEntry, response.data.payload.jwt, true)
                    } else {
                        logger.info(`Didn't send reverse injection data, shouldInjectSessionToNative: ${shouldInjectSessionToNative}`)
                    }
                    isSetPasswordSuccessful = true
                    if (this.resetDuringSecureFeature) {
                        await this.$router.go(-1)
                    } else {
                        await this.getAccountOverview()
                    }
                } catch (error) {
                    if (error.response.status === 401 ) {
                        this.resetPasswordToken = ''
                        this.passwordFirstEntry = ""
                        this.passwordConfirmationEntry = ""
                        this.showPersonalInfoInput = true
                        this.dateOfBirth = ""
                        this.ssnNumber = ""
                        logger.info(`setPassword api error -----> ${error.response.data.error}`)
                        if (error.response.data.error === "PASSCODE_RESET_TOKEN_EXPIRED") {
                            this.blockingError = this.getResetTokenExpiredError()
                        } else if (error.response.data.error === "INCORRECT_SSN_OR_DOB_ERROR") {
                            this.$nextTick(() => {
                                this.password = ''
                                this.ssnNumber = ''
                                this.dateOfBirth = ''
                                this.$refs.dateOfBirthFormField.clearValue()
                                this.applyGeneralError(i18n.t("pages.setPassword.error.personalInfoMismatchError"))
                            })
                        } else {
                            logger.error(`submitPassword failed with error ${inspect(error)}`)
                            this.blockingError = this.getGenericBlockError()
                        }
                    } else {
                        logger.error(`submitPassword failed with error ${inspect(error)}`)
                        if (!this.resetDuringSecureFeature) {
                            appSessionStorage.clear()
                        }
                        this.blockingError = this.getGenericBlockError()
                    }
                } finally {
                    this.loading = isSetPasswordSuccessful
                    this.submitting = false
                }

            },

            applyGeneralError: function(errorMessage) {
                this.$refs.personalInfoForm.applyGeneralError(errorMessage)
            },
            clearCreatePasswordError: function() {
                this.$refs.setPasswordForm.clearErrorMessage()
            },
            blockingErrorButtonClicked: function() {
                if (this.resetDuringSecureFeature) {
                    this.$router.go(-1)
                } else {
                    this.$router.replace('/login')
                }
            },
            getAccountOverview: async function () {
                if (appSessionStorage.getItem(localStorageKey.jwtToken)) {
                    try {
                        await this.$store.dispatch('updateAccountOverview')
                        if (this.isCardActivated) {
                            await postLoginNavigation()
                        } else {
                            await this.$router.push({
                                name: "CardArrivalCheck",
                            })
                        }
                    } catch (e) {
                        await this.$router.push({
                            name: "PasscodeLogin"
                        })
                    }

                } else {
                    console.log("No jwtToken found")
                }
            },
            getResetTokenExpiredError: function () {
                return {
                    type: ResetError.EXPIRED_TOKEN,
                    title: i18n.t("pages.setPassword.error.expiredTokenError.title"),
                    description: i18n.t("pages.setPassword.error.expiredTokenError.description"),
                    cta: this.resetDuringSecureFeature ?
                        i18n.t("global.cta.dismiss")
                        : i18n.t("pages.setPassword.error.expiredTokenError.cta")
                }
            },
            getGenericBlockError: function () {
                return {
                    type: ResetError.GENERIC,
                    title: i18n.t("global.errors.title"),
                    description: i18n.t("global.errors.generic"),
                    cta: this.resetDuringSecureFeature ?
                        i18n.t("global.cta.dismiss")
                        : i18n.t("global.cta.goToLogin")
                }
            }
        }
    };
</script>
