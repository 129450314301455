<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validation"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <cleave
                    ref="formFieldInput"
                    type="tel"
                    inputmode="decimal"
                    class="form-control"
                    :form-classes="formClasses"
                    :raw="false"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : valid ? 'is-valid' : null]"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    autocomplete="date"
                    :value="value"
                    v-model="cleaveValue"
                />
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin, formInputCleaveMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormFieldDate',
        mixins: [formInputMixin, formInputCleaveMixin],
        props: {
            validation: { type: String, default: 'required' },
            options: {
                type: Object,
                default: function () {
                    return { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] }
                },
            },
        },
    }
</script>
